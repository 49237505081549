@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/fonts';
@import '../../../../../assets/styles/mixins';
@import '../../../../../assets/styles/typography';

.form_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 50px;
  @include media(s-device){
    flex-direction: column;
  }

  &_input_field {
    position: relative;
    width: 70%;
    padding-right: 15px;
    @include media(s-device){
      position: unset;
      padding-right: unset;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  &_btn {
    width: 30%;
    @include media(s-device){
      width: 100%;
    }
  }

  &_img {
    width: 100%;
  }

  &_result {
    z-index: 1;
    position: absolute;
    width: 96.5%;
    padding: 10px;
    justify-content: center;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 12px 12px 48px 0 $box-shadow;
    border: solid 1px $ui-quaternary-muted;
    background-color: white;

    @include media(m-device) {
      display: block;
      overflow-wrap: break-word;
    }

    @include media(s-device) {
      position: unset;
      width: 100%;
    }
  }

  &_one_result {
    cursor: pointer;
    padding: 12px;
    border-radius: 8px;
    font-size: $level2;
    color: $txt-primary;
    font-family: $primary-reg;

    & > span > b {
      font-family: $primary-bold;
    }
  }

  &_one_result:hover {
    background-color: $ui-quaternary-muted;
  }
}
